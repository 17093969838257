.dropdown{
    position: relative;
    display: inline-block;
}
.dropdown-content{
    display: none;
}
.transi{
    transition: 1.2s;
  }
.worksan{
font-family: 'Work Sans', sans-serif;
}
.poppins{
    font-family: 'Poppins', sans-serif;
}
.mulish{
    font-family: 'Mulish', sans-serif;
}
.bgclr{
    background-color: rgb(191,202,2);
  }
.textclr{
    color: rgb(191,202,2);
}
  .bgpattern{
    background-image: url(../Asset/bgpattern.png);
  }
.banner1{
    background-image: url(../Asset/HomeBanner1.jpg);
    background-position: center;
    background-size: cover;
    min-height: 600px;
    animation: zoom 5s infinite alternate;
}
.banner2{
    background-image: url(../Asset/HomeBanner2.jpg);
    background-position: center;
    background-size: cover;
    min-height: 600px;
    animation: zoom 5s infinite alternate;
}
.banner3{
    background-image: url(../Asset/HomeBanner3.jpg);
    background-position: center;
    background-size: cover;
    min-height: 600px;
    animation: zoom 5s infinite alternate;
}
.banner4{
  background-image: url(../Asset/Renovation1.jpg);
  background-position: center;
  background-size: cover;
  min-height: 600px;
  animation: zoom 5s infinite alternate;
}
.banner5{
  background-image: url(../Asset/Renovation2.jpg);
  background-position: center;
  background-size: cover;
  min-height: 600px;
  animation: zoom 5s infinite alternate;
}
.banner6{
  background-image: url(../Asset/Renovation3.jpg);
  background-position: center;
  background-size: cover;
  min-height: 600px;
  animation: zoom 5s infinite alternate;
}
@media (min-width:1100px) {
    .banner1,.banner2,.banner3,.banner4,.banner5,.banner6{
        height: 86vh;
        
    }
    
}
@keyframes zoom {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }

  .outlined-text {
    color: transparent; /* Text color */
    -webkit-text-stroke: 1px #faf7f7; /* Width and color of the text outline for WebKit browsers (Chrome, Safari) */
    text-stroke: 2px #f8f6f6; /* Width and color of the text outline for other browsers */
}
  .slick-slide >div{
    margin: 0px 10px;
  }
  .transi{
    transition: 1.1s;
  }
#opchi{

  }
#opchi:hover{
  background-color: rgb(191,202,2,.8);
  opacity: 1;
}
#opchi>p{
    transform: translateY(15px);
    transition: 0.5s;
}
#opchi:hover>p{
    transform: translateY(0px);
    opacity: 1;
}
#opchi:hover>#expandicon{
   transform: translateX(0px);
   transition: 1s;
   opacity: 1;
}
.interiorbanner{
  background-image: url(../Asset//InteriorBanner3.jpg);
  background-position: center;
}
.realestateBanner{
  background-image: url(../Asset/RealEstateBanner.jpeg);
  background-position: center;
}
.constructionbanner{
  background-image: url(../Asset/constructionBanner2.webp);
  background-position: center;
  background-size: cover;
}
.sourcesan{
  font-family: 'Source Sans 3', sans-serif;
}
.bgappointment{
  background-image: url("../Asset/serviceappointment.jpg");
}
#expandicon{
  width: 20px;
  opacity: 0;
  float: inline-end;
  transform:translateX(-25px) ;
}
.pagination{
  list-style: none;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  gap: 10px;
  margin-top: 5rem;
}
.pagination .page-num{
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
  text-decoration: none;
  color: blue;
  font-weight: 200;
  /* transition: 1s; */
  border-radius: 50%;
  /* background-color: rgb(109,40,217); */
}
.pagination .page-num:hover{
  background-color: rgb(191,202,2);
  color: white;
  transition: 0.8s;

}
.pagination .active{
  background-color: rgb(191,202,2);
  color: white;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
input[type="number"] {
            -moz-appearance: textfield; /* Firefox */
        }
.scroll-bar::-webkit-scrollbar{
  width: 12px;
}
.scroll-bar::-webkit-scrollbar-thumb{
  background-color: #888;
  border-radius: 6px;
}
.scroll-bar::-webkit-scrollbar-corner{
  background-color: #f7f4f4;
}
.dark-modal .modal-content {
  background-color: rgb(0, 0, 0); /* Set your desired dark background color */
  color: #fff; /* Set your desired text color */
}

.dark-modal .modal-header {
  border-bottom: 1px solid #555; /* Set your desired border color */
}

.dark-modal .modal-footer {
  border-top: 1px solid #555; /* Set your desired border color */
}