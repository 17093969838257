@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.slick-slide >div{
  margin: 0px 10px;
}



